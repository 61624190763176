import React from 'react'
import Seo from "../../components/seo/index";
import Layout from "./layout/index";
import CompanyRegTable from './components/company-reg-table'

const CompanyRegistration = () => {
  return (
    <div>
    <Seo title="Green Heartfulness Run 2024" />
    <Layout isSticky>
     <CompanyRegTable />
    </Layout>
  </div>
  )
}

export default CompanyRegistration